:root { 
--teal_400_01:#2e9f8b; 
--light_blue_A400:#00c1ff; 
--blue_A700:#005df8; 
--light_blue_100:#a6d9ff; 
--gray_600_19:#85858519; 
--indigo_A200:#426cff; 
--deep_purple_400:#7e5cc5; 
--light_blue_400:#18abff; 
--white_A700_73:#ffffff73; 
--gray_50:#f8f8f8; 
--teal_200:#7dd5b5; 
--teal_400:#2d9f8a; 
--black_900_01:#000000; 
--black_900:#010108; 
--pink_A400:#ff007a; 
--purple_900:#551c98; 
--deep_orange_300:#ee9563; 
--purple_A400:#e100ff; 
--blue_gray_900_00:#1b0f4e00; 
--white_A700_38:#ffffff38; 
--deep_purple_A200:#9949ff; 
--blue_gray_900:#1b0f4e; 
--gray_400_99:#c5c5c599; 
--deep_purple_A700:#7f00ff; 
--purple_A100:#d376ff; 
--purple_600:#9f00a2; 
--pink_400:#e64467; 
--deep_purple_A200_01:#7f42ff; 
--deep_purple_A200_02:#985aff; 
--teal_A700:#00c187; 
--gray_900_02:#161e25; 
--gray_900_03:#292929; 
--gray_400:#c5c5c5; 
--blue_gray_200:#a7b5c4; 
--gray_900_04:#1a1a1a; 
--gray_800:#393d34; 
--gray_900:#241b2f; 
--gray_900_01:#171e25; 
--teal_A400:#31d6b8; 
--indigo_A700:#043bff; 
--black_900_73:#00000073; 
--blue_gray_200_19:#a7b5c419; 
--white_A700:#ffffff; 
--deep_purple_A200_99:#7f42ff99; 
}